var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"step-wizard"},[_c('ul',{staticClass:"step-wizard__list"},_vm._l((_vm.steps),function(step,index){return _c('li',{key:index,staticClass:"step-wizard__item"},[_c('div',{staticClass:"step-wizard__status",class:{
            'step-wizard__status--active': index === _vm.state,
            'step-wizard__status--done': _vm.state > index,
          }}),_c('div',{staticClass:"step-wizard__step-line",class:{
            'step-wizard__step-line--last-child':
              index === _vm.steps.length - 1,
            'step-wizard__step-line--active': _vm.state > index,
            'step-wizard__step-line--pre-active':
              _vm.state - index === 1,
          }}),_c('div',{staticClass:"step-wizard__content"},[_c('p',{staticClass:"step-wizard__title"},[_c('span',{staticClass:"step-wizard__link",class:{ 'step-wizard__link--done': _vm.state > index },attrs:{"href":"#"}},[_vm._v(_vm._s(step.title)+" ")]),_c('Icon',{staticClass:"step-wizard__icon",class:{ 'step-wizard__icon--done': _vm.state > index },attrs:{"name":"arrow-up-right","group":"general","inline":"","size":13}})],1),_c('p',{staticClass:"step-wizard__desc"},[_vm._v(_vm._s(step.desc))])])])}),0)]),_c('div',{staticClass:"step-content"},[_c(_vm.stepContent,{tag:"component",attrs:{"vk-pay-status":_vm.vkPayStatus,"trust-doc-status":_vm.trustDocStatus}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }