<template>
  <div class="notification" :class="notificationModifyClass">
    <div v-if="showIcon" class="notification__icon">
      <Icon :name="iconCurrentName" :size="24" />
    </div>
    <div class="notification__content" :class="contentModifyClass">
      <div class="notification__text">
        <h3 class="notification__title">{{ title }}</h3>
        <p class="notification__description">
          {{ subtitle }}
        </p>
        <slot></slot>
      </div>
      <div v-if="isShowButtons" class="notification__buttons">
        <Button
          v-for="(button, idx) in buttons"
          :key="idx"
          size="regular"
          type="secondary"
          :text="button.text"
          @click="onClickHandler(button)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Notification',
  components: { Icon, Button },
  props: {
    type: {
      type: String,
      required: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      icons: {
        error: 'crossed-out-circle',
        warning: 'triangle-warning',
        success: 'check',
        info: 'circle-i',
      },
    };
  },
  computed: {
    notificationModifyClass() {
      return `notification--${this.type}`;
    },
    contentModifyClass() {
      return { 'notification__content--horizontal': this.horizontal };
    },
    iconCurrentName() {
      return this.icons[this.type];
    },
    isShowButtons() {
      return this.buttons.length;
    },
  },
  methods: {
    onClickHandler(data) {
      this.$emit('click', data);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@assets/scss/common/brakepoints';

.notification {
  display: flex;
  color: var(--main-color-white);
  background: #454549;
  border-radius: var(--border-radius--medium);
  overflow: hidden;

  &::before {
    content: '';
    flex-shrink: 0;
    width: 4px;
  }

  &--error {
    &::before {
      background: var(--additional-color-red);
    }
  }
  &--warning {
    &::before {
      background: var(--additional-color-orange);
    }
  }
  &--success {
    &::before {
      background: var(--additional-color-green);
    }
  }
  &--info {
    &::before {
      background: var(--additional-color-blue);
    }
  }
}

.notification__icon {
  padding: 12px 0 12px 20px;

  .notification--error & {
    color: var(--additional-color-red);
  }
  .notification--warning & {
    color: var(--additional-color-orange);
  }
  .notification--success & {
    color: var(--additional-color-green);
  }
  .notification--info & {
    color: var(--additional-color-blue);
  }
}

.notification__content {
  width: 100%;
  padding: 12px 16px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}

.notification__content--horizontal {
  @include min-tablet {
    flex-direction: row;
    align-items: center;

    .notification__buttons {
      flex-shrink: 0;
    }

    .notification__buttons button {
      width: unset;
    }
  }
}

.notification__text {
  width: 100%;
}

.notification__title,
.notification__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.notification__buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @include min-tablet {
    flex-wrap: nowrap;
  }

  button {
    width: 100%;
  }
}
</style>
