<template>
  <div class="wrapper">
    <div class="step-wizard">
      <ul class="step-wizard__list">
        <li
          v-for="(step, index) in steps"
          :key="index"
          class="step-wizard__item"
        >
          <div
            class="step-wizard__status"
            :class="{
              'step-wizard__status--active': index === state,
              'step-wizard__status--done': state > index,
            }"
          ></div>
          <div
            class="step-wizard__step-line"
            :class="{
              'step-wizard__step-line--last-child':
                index === steps.length - 1,
              'step-wizard__step-line--active': state > index,
              'step-wizard__step-line--pre-active':
                state - index === 1,
            }"
          ></div>
          <div class="step-wizard__content">
            <p class="step-wizard__title">
              <span
                class="step-wizard__link"
                :class="{ 'step-wizard__link--done': state > index }"
                href="#"
                >{{ step.title }}
              </span>
              <Icon
                class="step-wizard__icon"
                :class="{ 'step-wizard__icon--done': state > index }"
                name="arrow-up-right"
                group="general"
                inline
                :size="13"
              />
            </p>
            <p class="step-wizard__desc">{{ step.desc }}</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="step-content">
      <component
        :is="stepContent"
        :vk-pay-status="vkPayStatus"
        :trust-doc-status="trustDocStatus"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';
import LinkVkId from './LinkVkId.vue';
import LinkVkpayWallet from './LinkVkpayWallet.vue';
import IdentifyVkpayWallet from './IdentifyVkpayWallet.vue';
import FormForPayment from './FormForPayment.vue';
import FinishStep from './FinishStep.vue';

export default {
  name: 'StepWizard',
  components: {
    Icon,
    LinkVkId,
    LinkVkpayWallet,
    IdentifyVkpayWallet,
    FormForPayment,
    FinishStep,
  },
  props: {
    state: {
      type: Number,
      default: 0,
    },
    vkPayStatus: {
      type: String,
      required: true,
    },
    trustDocStatus: {
      type: [String, null],
      required: true,
    },
  },
  data() {
    return {
      steps: [
        {
          title: 'Привяжи VK ID',
          desc: 'Этот шаг занимает всего пару минут!',
        },
        {
          title: 'Создай VK Pay кошелек',
          desc: 'На него тебе будут поступать денежные средства',
        },
        {
          title: 'Идентифицируй VK Pay кошелек',
          desc: 'Это обязательный шаг для перевода и вывода наград',
        },
        {
          title: 'Заполни анкету для выплат',
          desc: 'Всё, что нужно, это заполнить анкету',
        },
      ],
    };
  },
  computed: {
    stepContent() {
      switch (this.state) {
        case 0:
          return 'LinkVkId';
        case 1:
          return 'LinkVkpayWallet';
        case 2:
          return 'IdentifyVkpayWallet';
        case 3:
          return 'FormForPayment';
        case 4:
          return 'FinishStep';
        default:
          return 'LinkVkId';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  gap: 40px;
}

.step-wizard {
  padding: 16px;
  border-radius: 4px;
  background-color: #2d2d30;
  max-width: 350px;
}

.step-wizard__list {
  list-style: none;
}

.step-wizard__item {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
  position: relative;
}

.step-wizard__step-line {
  width: 4px;
  height: 110%;
  background-color: #454549;
  position: absolute;
  top: 16px;
  left: 6px;
}

.step-wizard__step-line--last-child {
  display: none;
}

.step-wizard__step-line--active {
  background-color: #186cea;
}

.step-wizard__step-line--pre-active {
  background: linear-gradient(180deg, #186cea 60%, #65cb94 100%);
}

.step-wizard__title {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}

.step-wizard__status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #454549;

  &--active {
    border: 4px solid #65cb6d;
  }

  &--done {
    background-color: #186cea;
  }
}

.step-wizard__content {
  width: calc(100% - 16px);
}

.step-wizard__link {
  color: inherit;
  margin-right: 8px;
}

.step-wizard__link--done {
  text-decoration: underline;
}

.step-wizard__icon {
  visibility: hidden;
}

.step-wizard__icon--done {
  visibility: visible;
}

.step-wizard__desc {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
}
</style>
