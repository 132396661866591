<template>
  <div class="finish-step">
    <p class="finish-step__desc">VK Pay кошелек идентифицирован</p>
    <Label text="Готово" />
  </div>
</template>

<script>
import Label from './Label.vue';

export default {
  name: 'FinishStep',
  components: {
    Label,
  },
};
</script>

<style lang="scss" scoped>
.finish-step {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.finish-step__desc {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-right: 36px;
}
</style>
