<template>
  <div class="identify-wallet">
    <h3 class="identify-wallet__header">
      Идентифицируй VK Pay кошелек
    </h3>
    <p class="identify-wallet__desc">
      Идентификация — это просто и быстро. Она необходима в
      соответствии с Федеральным законом No 115-ФЗ. Если твой кошелек
      уже идентифицирован, переходи к следующему шагу
    </p>
    <p class="identify-wallet__instruction">
      Тут можешь посмотреть
      <a href="https://vkpay.com/faq/id/"
        >инструкцию по идентификации кошелька</a
      >
    </p>
    <Button
      class="identify-wallet__button"
      size="big"
      type="primary"
      long
      tag="a"
      target="_blank"
      href="https://vk.com/miniapps_vkpay"
      text="Идентифицировать"
    />
    <p class="identify-wallet__note">
      После переходи обратно, чтобы продолжить заполнение данных
    </p>
    <div class="identify-wallet__footer">
      <a href="https://support.vkplay.ru/pvp/questions/4151"
        >Остались вопросы? Обратитесь в поддержку.</a
      >
    </div>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'IdentifyVkpayWallet',
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.identify-wallet {
  max-width: 414px;
}

.identify-wallet__header {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 6px;
}

.identify-wallet__desc {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.identify-wallet__instruction {
  margin-bottom: 20px;
}

.identify-wallet__button {
  margin-bottom: 12px;
}

.identify-wallet__note {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 12px;
}
</style>
