<template>
  <div class="content">
    <loader v-if="isLoading" class="loader" size="64" />
    <div v-else>
      <div class="back" @click="$router.push(backUrl)">
        <Icon
          class="back__arrow"
          name="arrowLeft"
          group="general"
          size="32"
          inline
        />
        Ко всем наградам
      </div>
      <StepWizard
        :state="state"
        :vk-pay-status="vkPayStatus"
        :trust-doc-status="trustDocStatus"
      />
    </div>
  </div>
</template>

<script>
import StepWizard from './ui/StepWizard.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'AutoPayments',
  components: {
    Icon,
    StepWizard,
  },
  data() {
    return {
      isLoading: true,
      state: 0,
      vkId: null,
      vkPayStatus: null,
      trustDocStatus: null,
    };
  },
  computed: {
    ...mapGetters('profile', ['getCurrentProfileHash']),

    userHash() {
      return this.getCurrentProfileHash;
    },

    backUrl() {
      return `player/${this.userHash}/rewards`;
    },
  },
  mounted() {
    this.init();

    window.addEventListener(
      'visibilitychange',
      this.handleRefreshWizardData,
    );
  },

  beforeDestroy() {
    window.removeEventListener(
      'visibilitychange',
      this.handleRefreshWizardData,
    );
  },

  methods: {
    init() {
      this.fetchWizardRefresh()
        // eslint-disable-next-line no-console
        .catch((err) => console.log(err))
        .finally(() => this.fetchWizardStatus());
    },

    handleRefreshWizardData() {
      if (document.visibilityState === 'visible') {
        this.init();
      }
    },

    setState(state) {
      const map = {
        vkId: 0,
        vkPay: 1,
        vkPayIdentification: 2,
        trustDoc: 3,
        completed: 4,
        error: 5,
      };
      return map[state];
    },

    async fetchWizardStatus() {
      const url = 'profile/payout/vkId/wizard';
      try {
        const { data } = await api.get(url);
        const { vkId, vkPayStatus, wizardState, trustDocStatus } =
          data;

        this.state = this.setState(wizardState);
        this.vkId = vkId;
        this.vkPayStatus = vkPayStatus;
        this.trustDocStatus = trustDocStatus;

        return data;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },

    async fetchWizardRefresh() {
      const url = 'profile/payout/vkId/wizard/refresh';
      return await api.post(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  max-width: 1224px;
  width: 100%;
  padding: 25px 12px;
  margin: 0 auto;
}

.back {
  color: #e2e3e7;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
</style>
