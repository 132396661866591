<template>
  <div class="label">
    <Icon name="check" group="general" :size="16" inline />
    {{ text }}
  </div>
</template>

<script>
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'Label',
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 20px;
  background: rgba(101, 203, 109, 0.25);
}
</style>
