<template>
  <div v-if="vkPayStatus === 'inactive'">
    <h3 class="vkpay-wallet__header">Привязать VK Pay кошелек</h3>
    <Notification
      class="vkpay-wallet__notification"
      type="error"
      horizontal
    >
      Ваш <a href="https://vk.com/miniapps_vkpay">VK pay</a> кошелек
      находиться в неактивном статусе.
      <a href="https://support.vkplay.ru/pvp/questions/4151"
        >Пожалуйста обратитесь в поддержку.</a
      >
    </Notification>
  </div>
  <div v-else class="vkpay-wallet">
    <h3 class="vkpay-wallet__header">Привязать VK Pay кошелек</h3>
    <p class="vkpay-wallet__desc">
      Если у тебя уже есть кошелек VK Pay, можно смело пропускать этот
      шаг.Если нет — не беда! Просто создай его в несколько кликов и
      готово!
    </p>
    <Button
      class="vkpay-wallet__button"
      size="big"
      type="primary"
      tag="a"
      target="_blank"
      href="https://vk.com/miniapps_vkpay"
      long
      text="Привязать VK Pay кошелек"
    />
    <p class="vkpay-wallet__note">
      После создания переходи обратно, чтобы продолжить заполнение
      данных
    </p>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Notification from '@components/v2/ui/Notification.vue';

export default {
  name: 'LinkVkpayWallet',
  components: {
    Button,
    Notification,
  },
  props: {
    vkPayStatus: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.vkpay-wallet {
  max-width: 414px;
}

.vkpay-wallet__header {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 6px;
}

.vkpay-wallet__desc {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.vkpay-wallet__button {
  margin-bottom: 12px;
}

.vkpay-wallet__note {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
}

.vkpay-wallet__notification {
  width: auto;
}
</style>
