import api from "@src/utils/api";

export default async () => {
    const url = 'profile/payout/vkId/trustDoc/link';
    const {
      data: { link },
    } = await api.post(url);
    window.open(link, '_blank');
  };

