<template>
  <div v-if="trustDocStatus === 'declined'">
    <h3 class="payment-form__header">Анкета для выплат</h3>
    <Notification
      class="payment-form__notification"
      type="error"
      horizontal
      title="У вас есть проблемы с данными в анкете. Отредактируйте анкету"
    />
    <Button
      class="payment-form__button"
      size="big"
      type="primary"
      long
      text="Редактировать анкету"
      :loading="isLoading"
      @click="getLinkToPaymentForm"
    />
  </div>
  <div v-else-if="trustDocStatus === 'processing'">
    <h3 class="payment-form__header">Анкета для выплат</h3>
    <Notification
      class="payment-form__notification"
      type="warning"
      horizontal
      title="Ваша анкета находится в обработке. Ожидайте."
    />
  </div>
  <div v-else class="payment-form">
    <h3 class="payment-form__header">Анкета для выплат</h3>
    <p class="payment-form__desc">
      Немного информации от тебя — и мы сможем перевести твои выигрыши
      и оплатить налоги.
    </p>
    <Button
      class="payment-form__button"
      size="big"
      type="primary"
      long
      text="Заполнить анкету"
      :loading="isLoading"
      @click="getLinkToPaymentForm"
    />
    <p class="payment-form__note">
      <a href="https://support.vkplay.ru/pvp/questions/4151"
        >Остались вопросы? Обратись в поддержку.</a
      >
    </p>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';
import Notification from '@components/v2/ui/Notification.vue';
import fetchLinkToPaymentForm from '@src/features/fetchLinkToPaymentForm';

export default {
  name: 'FormForPayment',
  components: {
    Button,
    Notification,
  },
  props: {
    trustDocStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async getLinkToPaymentForm() {
      this.isLoading = true;
      try {
        await fetchLinkToPaymentForm();
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-form {
  max-width: 414px;
}

.payment-form__header {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 6px;
}

.payment-form__desc {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.payment-form__button {
  margin-bottom: 12px;
}

.payment-form__note {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
}

.payment-form__notification {
  margin-bottom: 12px;
}
</style>
