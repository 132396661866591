<template>
  <div class="link-vkid">
    <h3 class="link-vkid__header">Привяжи VK ID</h3>
    <p class="link-vkid__desc">
      Всё начинается с VK ID. Это ваш уникальный идентификатор, к
      которому будет привязан VK Pay кошелек. Нажми на кнопку
      "Привязать VK ID” и привязать в профиле VK Play.
    </p>
    <Button
      class="link-vkid__button"
      size="big"
      type="primary"
      tag="a"
      target="_blank"
      href="https://account.vkplay.ru/profile/userinfo"
      long
      text="Привязать VK ID"
    />
    <p class="link-vkid__note">
      После привязки переходи обратно, чтобы продолжить заполнение
      данных.
    </p>
  </div>
</template>

<script>
import Button from '@components/v2/ui/Button.vue';

export default {
  name: 'LinkVkId',
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
.link-vkid {
  max-width: 414px;
}

.link-vkid__header {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin-bottom: 6px;
}

.link-vkid__desc {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.link-vkid__button {
  margin-bottom: 12px;
}

.link-vkid__note {
  color: #f5f5f7;
  font-size: 16px;
  font-weight: 400;
}
</style>
